<template>
  <div class="test-detail">
    <div class="inner">
      <el-breadcrumb class="breadcrumb mb20" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manager/test/list' }">考试阅卷</el-breadcrumb-item>
        <el-breadcrumb-item>考试详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="course-wrap">
        <div class="c-cover" :style="{ backgroundImage: 'url(' + examDetail.coverUrl + ')' }"></div>
        <div class="c-content">
          <div class="c-name">{{ examDetail.name }}</div>
          <div class="c-time">
            考试时间：{{ examDetail.examDate }} {{ examDetail.startTime }}-{{ examDetail.endTime }}
          </div>
          <div class="c-text">
            <span>考试时长：{{ examDetail.examDuration }}分钟</span>
            <span>通过分数：{{ examDetail.passScore }}分</span>
            <span>学科：{{ examDetail.subjectName }}</span>
            <span>课程：{{ examDetail.courseName }}</span>
          </div>
          <div class="c-desc">{{ examDetail.brief }}</div>
          <div class="c-more">
            <div class="c-num">报名人数：{{ examDetail.signupNum }}</div>
            <!-- <div class="c-tag"></div> -->
          </div>
        </div>
      </div>
      <div class="c-table" v-show="type == 0">
        <div class="header-full"><span>学员考试情况</span></div>
        <el-table stripe class="table" :data="courseStuList" style="width: 100%">
          <el-table-column prop="studentName" label="姓名" width="120"></el-table-column>
          <el-table-column prop="studentMobile" label="手机号" width="150"></el-table-column>
          <el-table-column prop="manuCheckFlag" label="人工阅卷" width="100">
            <template slot-scope="scope">
              <p v-if="scope.row.manuCheckFlag == 'n'">否</p>
              <p v-if="scope.row.manuCheckFlag == 'y'">是</p>
            </template>
          </el-table-column>
          <el-table-column label="考试日期" width="220">
            <template slot-scope="scope">
              <p>{{ scope.row.examDate }} {{ scope.row.startTime }}-{{ scope.row.endTime }}</p>
            </template>
          </el-table-column>
          <el-table-column label="是否已阅卷">
            <template slot-scope="scope">
              <p v-if="scope.row.reviewFlag == 'n'">未阅</p>
              <p v-if="scope.row.reviewFlag == 'y'">已阅</p>
            </template>
          </el-table-column>
          <el-table-column prop="studentExamDuration" label="用时(分钟)"></el-table-column>
          <el-table-column prop="studentScore" label="得分"></el-table-column>
          <el-table-column label="是否通过">
            <template slot-scope="scope">
              <p v-if="scope.row.passFlag == 'n'">未通过</p>
              <p v-if="scope.row.passFlag == 'y'">通过</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="
                  href('/manager/test/result', {
                    examId: scope.row.examId,
                    studentId: scope.row.studentId
                  })
                "
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="c-table" v-show="type == 1">
        <div class="header-full"><span>学员考试情况</span></div>
        <el-table stripe class="table" :data="ExamStuList" style="width: 100%">
          <el-table-column prop="studentName" label="姓名" width="120"></el-table-column>
          <el-table-column prop="studentMobile" label="手机号" width="150"></el-table-column>
          <el-table-column prop="manuCheckFlag" label="人工阅卷" width="100">
            <template slot-scope="scope">
              <p v-if="scope.row.manuCheckFlag == 'n'">否</p>
              <p v-if="scope.row.manuCheckFlag == 'y'">是</p>
            </template>
          </el-table-column>
          <el-table-column label="考试日期" width="220">
            <template slot-scope="scope">
              <p>{{ scope.row.examDate }} {{ scope.row.startTime }}-{{ scope.row.endTime }}</p>
            </template>
          </el-table-column>
          <el-table-column label="是否已阅卷">
            <template slot-scope="scope">
              <p v-if="scope.row.reviewFlag == 'n'">未阅</p>
              <p v-if="scope.row.reviewFlag == 'y'">已阅</p>
            </template>
          </el-table-column>
          <el-table-column prop="studentExamDuration" label="用时(分钟)"></el-table-column>
          <el-table-column prop="studentScore" label="得分"></el-table-column>
          <el-table-column label="是否通过">
            <template slot-scope="scope">
              <p
                v-if="
                  scope.row.passFlag == 'n' ||
                    scope.row.passFlag == null ||
                    scope.row.passFlag == ''
                "
              >
                未通过
              </p>
              <p v-if="scope.row.passFlag == 'y'">通过</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="
                  href('/manager/test/result', {
                    examId: scope.row.examId,
                    studentId: scope.row.studentId
                  })
                "
              >
                详情
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="
                  href('/manager/test/check', {
                    examId: scope.row.examId,
                    studentId: scope.row.studentId
                  })
                "
              >
                阅卷
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="page mt50"
        prev-text="上一页"
        next-text="下一页"
        background
        layout="prev, pager, next"
        :total="totalPages"
        :page-size.sync="pageSize"
        @current-change="currentChange"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      // 总条数
      totalPages: 0,
      // 每页条数
      pageSize: 10,
      // 当前页码
      pageIndex: 1,
      examId: this.$route.query.examId,
      type: this.$route.query.type,
      examDetail: {},
      courseStuList: [],
      ExamStuList: []
    };
  },
  computed: {},
  created() {
    this.getExamDetail();
    if (this.type == 0) {
      this.getCourseStudentList();
    } else if (this.type == 1) {
      this.getExamStudentList();
    }
  },
  mounted() {},
  watch: {},
  methods: {
    getExamDetail() {
      this.get(`/biz/teacher/qb/examDetail/${this.examId}`, {}, e => {
        if (e.code == 200) {
          this.examDetail = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 我的课程-学员考试情况列表 type = 0
    getCourseStudentList() {
      this.get(
        `/biz/teacher/qb/review/${this.examId}/studentExamList?pageNum=${this.pageIndex}&pageSize=${
          this.pageSize
        }`,
        {},
        e => {
          if (e.code == 200) {
            this.courseStuList = e.rows;
            this.totalPages = e.total;
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 需我阅卷-学员考试情况列表 type = 1
    getExamStudentList() {
      this.post(
        `/biz/teacher/qb/reviewStudentExamList?pageNum=${this.pageIndex}&pageSize=${this.pageSize}`,
        { examId: this.examId },
        e => {
          if (e.code == 200) {
            this.ExamStuList = e.rows;
            this.totalPages = e.total;
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 当前页变化时执行
    currentChange(e) {
      this.pageIndex = e;
      if (this.type == 0) {
        this.getCourseStudentList();
      } else if (this.type == 1) {
        this.getExamStudentList();
      }
    }
  },
  components: {}
};
</script>

<style></style>
